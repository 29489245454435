import {
  AccessTimeOutlined,
  InfoOutlined,
  ReportOutlined,
} from "@mui/icons-material";
import {
  Card,
  Typography,
  Tooltip,
  Fade,
  Button,
  Box,
  CircularProgress,
  Skeleton,
  Divider,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import moment from "moment";
import Calendar from "../../../components/Calendar/Calendar";
import PageWrapper from "../../PageWrapper";
import Availabilities from "../ChooseTime/Availabilities";
import Days from "../ChooseTime/Days";
import { useApp } from "../../../context/AppContext";
import { useSnackbar } from "../../../context/SnackbarContext";
import { useGetBooking } from "../../../hooks/useGetBooking";
import { useEffect, useState } from "react";
import { Navigate, useLocation, useParams } from "react-router-dom";
import {
  Bookings,
  PharmacyAvailability,
  Service,
  UpdateBookingMutation,
  UpdateBookingMutationVariables,
  UpdateBookingsInput,
} from "../../../codegens/HEALTCARE-RESERVATION/__generated__/graphql";
import { GET_BOOKING } from "../../../codegens/HEALTCARE-RESERVATION/queries";
import { useMutation } from "@apollo/client";
import { MUTATION_UPDATE_BOOKING } from "../../../codegens/HEALTCARE-RESERVATION/mutations";
import { dateLib } from "../../../lib/_calendar";
import { Pharmacy } from "../../../codegens/AIR-API/__generated__/graphql";
import { useModal } from "../../../context/DialogContext";
import ModalSuccessContent from "./ModalSuccessContent";
import { IPharmacy } from "../../../interfaces/IPharamacy";
import { useServices } from "../../../hooks/useServices";
import useApi from "../../../hooks/useApi";
import { BOOKING_STATUS, IBooking } from "../../../interfaces/IBooking";
import ModalConfirm from "./ModalConfirm";
import {
  formatToABooking,
  formatToApharmacy,
  formatToAService,
  formatToAvailability,
} from "@/lib/layers";
import { useReservationEndpoint } from "@/hooks/useReservationEndpoint";
import ModalLoadingContent from "@/components/ModalLoadingContent/ModalLoadingContent";
import ModalReservationCreated from "../ReviewAppointment/ModalReservationCreated";
import ModalReservationUpdated from "../ReviewAppointment/ModalReservationUpdated";
import { isWithin24Hours } from "@/lib/utils";

type Props = {};

const Header = ({ booking }: { booking: IBooking | null }) => {
  const { setSelectedPharmacy } = useApp();
  // const { getPharmacy, loading, error } = usePharmacy();
  const { getPharmacy, loading, error } = useReservationEndpoint();

  const [pharmacy, setPharmacy] = useState<IPharmacy | null>(null);

  useEffect(() => {
    if (booking) {
      getPharmacy(booking.pharmacyID)
        .then((response) => {
          const pharmacy = response?.data as Pharmacy;
          setPharmacy(formatToApharmacy(pharmacy));
          setSelectedPharmacy(formatToApharmacy(pharmacy));
        })
        .catch((reason) =>
          console.error("Error getting pharmacy detail ", reason)
        );
    }
  }, [booking]);

  if (loading) {
    return (
      <div className="lg:flex xl:flex  xl:gap-4 lg:gap-4 flex gap-4">
        <span className="lg:flex xl:flex lg:flex-col xl:flex-col lg:justify-start xl:justify-start lg:items-start xl:items-start lg:w-full xl:w-full flex flex-col gap-2 ">
          <Skeleton variant="text" width={150} sx={{ fontSize: "2rem" }} />
          <Skeleton variant="text" width={300} sx={{ fontSize: "1rem" }} />
        </span>
      </div>
    );
  }

  if (error) {
    return (
      <Box>
        <Typography>
          Sorry, we are having a problem getting the pharmacy detail. Please
          feel free to <a href="mailto:admin@dragatron.com.au">report</a> this
          problem.
        </Typography>
      </Box>
    );
  }

  return (
    <div className="lg:flex xl:flex  xl:gap-4 lg:gap-4 flex gap-4">
      <span className="lg:flex xl:flex lg:flex-col xl:flex-col lg:justify-start xl:justify-start lg:items-start xl:items-start lg:w-full xl:w-full flex flex-col gap-2 ">
        <span className=" lg:text-lg xl:text-lg lg:font-semibold xl:font-semibold  md:font-semibold md:text-lg ">
          {pharmacy?.pharmacyName}
        </span>
        <Typography className="xl:text-sm lg:text-sm md:text-sm text-base text-grey-400 font-medium">
          Currently, we only support updating the time. Here are the available
          slots for {pharmacy?.pharmacyName}
        </Typography>
      </span>
    </div>
  );
};

const UpdateAppointment = (props: Props) => {
  const {
    selectedAvailability,
    setSelectedService,
    setSelectedAvailability,
    selectedPharmacy,
  } = useApp();

  const { getAvailabilitiyById, loading, error } = useReservationEndpoint();

  const { openSnackbar } = useSnackbar();
  const { fetchBooking, loadingGetBooking, errorGetBooking, data } =
    useGetBooking();
  const { bookingID } = useParams();
  const { openModal } = useModal();
  const { getService } = useServices();
  const api = useApi();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.between("xs", "sm"));

  const [booking, setBooking] = useState<IBooking | null>(null);

  const [initBooking, setInitBooking] = useState<IBooking | null>(null);
  const [loadingSubmit, setLaodingSubmit] = useState(false);

  const [
    updateBooking,
    {
      data: updateBookingResponse,
      error: errorUpdateBookingResponse,
      loading: loadingUpdateBooking,
    },
  ] = useMutation<UpdateBookingMutation, UpdateBookingMutationVariables>(
    MUTATION_UPDATE_BOOKING
  );

  const { pathname } = useLocation();

  useEffect(() => {
    if (loadingSubmit) {
      openModal({
        title: null,
        backdropClose: false,
        content: <ModalLoadingContent />,
      });
    }
  }, [loadingSubmit]);

  useEffect(() => {
    if (bookingID) {
      fetchBooking({
        variables: {
          bookingID,
        },
        onCompleted(data) {
          const booking = formatToABooking(data.getBookings as Bookings);
          setBooking(booking || null);
          setInitBooking(booking || null);
        },
      });
    }
  }, [bookingID]);

  useEffect(() => {
    if (booking) {
      getService({
        variables: {
          serviceId: booking.serviceId,
        },
        onCompleted(data) {
          const service = data.getService;

          if (service) {
            setSelectedService(formatToAService(service as Service));
          }
        },
      });

      console.log("Booking ", booking);

      if (!selectedAvailability) {
        getAvailabilitiyById(booking.availabilityID)
          .then((response) => {
            const result = formatToAvailability(
              response?.data as PharmacyAvailability
            );

            setSelectedAvailability(result);
          })
          .catch((reason) =>
            console.error("Error fetching availability ", reason)
          );
      }
    }
  }, [booking]);

  useEffect(() => {
    if (selectedAvailability) {
      setBooking((prev) => ({
        ...prev!,
        availabilityID: selectedAvailability.availabilityID,
        timeslot: selectedAvailability.timeslot,
      }));
    }
  }, [selectedAvailability]);

  // error listener update booking
  useEffect(() => {
    if (errorUpdateBookingResponse) {
      openSnackbar({
        message: "Sorry, we are having a problem processing your request",
        action: (
          <Button
            size="small"
            onClick={() => {
              const bodyString = `Error: ${JSON.stringify(
                errorUpdateBookingResponse
              )} \n page: ${pathname} \n Result Query: ${JSON.stringify(
                data
              )} \n Query Used: ${JSON.stringify(
                MUTATION_UPDATE_BOOKING.loc?.source
              )}`;
              const encodedBodyString = encodeURIComponent(bodyString).replace(
                /%0A/g,
                "%0D%0A"
              );
              window.open(
                `mailto:admin@dragatron.com.au?subject=Healthcare Reservation Problem&body=${encodedBodyString}`
              );
            }}
            startIcon={<ReportOutlined />}
          >
            Report
          </Button>
        ),
        severity: "error",
      });
    }
  }, [errorUpdateBookingResponse]);

  // success listener
  useEffect(() => {
    if (updateBookingResponse) {
      const response = formatToABooking(
        updateBookingResponse.updateBookings as Bookings
      );
      if (response) {
        openModal({
          title: null,
          fullWidth: true,
          backdropClose: false,
          content: <ModalSuccessContent booking={response} />,
        });
      }
    }
  }, [updateBookingResponse]);

  if (loadingGetBooking) {
    return (
      <PageWrapper title="Select Time">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            width: "100%",
          }}
        >
          <Card
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              height: "100%",
              width: "100%",
            }}
          >
            <CircularProgress />
            <Typography>Loading Appointment...</Typography>
          </Card>
        </Box>
      </PageWrapper>
    );
  }

  if (errorGetBooking) {
    return (
      <PageWrapper title="Select Time">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "70vh",
            width: "100%",
          }}
        >
          <Card
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              height: "100%",
              width: "100%",
              px: "3rem",
            }}
          >
            <Box
              display="flex"
              alignItems="center"
              flexDirection="column"
              gap="1rem"
            >
              <Typography
                sx={{
                  textAlign: "center",
                }}
              >
                Sorry, we are having a problem processing your request. Feel
                free to report this
              </Typography>
              <Button
                size="small"
                color="error"
                variant="outlined"
                onClick={() => {
                  const bodyString = `Error: ${JSON.stringify(
                    errorGetBooking
                  )} \n page: ${pathname} \n Result Query: ${JSON.stringify(
                    data
                  )} \n Query Used: ${JSON.stringify(GET_BOOKING.loc?.source)}`;
                  const encodedBodyString = encodeURIComponent(
                    bodyString
                  ).replace(/%0A/g, "%0D%0A");
                  window.open(
                    `mailto:admin@dragatron.com.au?subject=Healthcare Reservation Problem&body=${encodedBodyString}`
                  );
                }}
                startIcon={<ReportOutlined color="error" />}
              >
                Report
              </Button>
            </Box>
          </Card>
        </Box>
      </PageWrapper>
    );
  }

  if (!booking && !errorGetBooking && !loadingGetBooking) {
    return (
      <PageWrapper title="Select Time">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "70vh",
            width: "100%",
          }}
        >
          <Card
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              height: "100%",
              width: "100%",
            }}
          >
            <Box>
              <Typography gutterBottom className="text-center">
                Reservation not found
              </Typography>
              <Typography className="text-center" variant="body2">
                Feel free to contact us if you believe this is a mistake
                at&nbsp;
                <a href="mailto:admin@dragatron.com.au">here</a>
              </Typography>
            </Box>
          </Card>
        </Box>
      </PageWrapper>
    );
  }

  const handleUpdateBooking = async () => {
    if (!selectedAvailability) {
      console.info("Please select the availability");
      return;
    }
    setLaodingSubmit(true);
    try {
      const payload: UpdateBookingsInput = {
        bookingID: booking!.bookingID,
        availabilityID: selectedAvailability.availabilityID,
        timeslot: selectedAvailability.timeslot,
        updatedDate: dateLib().toISOString(),
        BookingMonth: moment.months(
          dateLib(selectedAvailability.timeslot).clone().month()
        ),
      };

      // const { data: response, errors } = await updateBooking({
      //   variables: {
      //     input: payload,
      //   },
      // });

      // if (!response?.updateBookings) {
      //   const error = new Error(
      //     `Failed updating booking ${JSON.stringify(errors)}`
      //   );
      //   error.name = "ErrorUpdateBookingException";
      //   throw error;
      // }

      const response = await api.post("/updateAppointment", {
        ...payload,
        source: "WEB",
      });
      if (response.status !== 200) {
        throw new Error(response.data);
      }

      openModal({
        title: null,
        fullWidth: true,
        backdropClose: false,
        content: (
          <ModalReservationUpdated
            bookingId={payload.bookingID}
            status="Updated"
          />
        ),
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLaodingSubmit(false);
    }
  };

  const handleCancelBooking = async () => {
    if (!booking) {
      console.info("Somehow, booking not initiated yet");
      return;
    }
    setLaodingSubmit(true);

    try {
      const payload: UpdateBookingsInput = {
        bookingID: booking.bookingID,
        status: BOOKING_STATUS.CANCELED_BY_PATIENT,
      };

      const response = await api.post("/updateAppointment", {
        ...payload,
        source: "WEB",
      });

      if (response.status !== 200) {
        throw new Error(response.data);
      }

      console.info("Updated");

      openModal({
        title: null,
        fullWidth: true,
        backdropClose: false,
        content: (
          <ModalReservationUpdated
            bookingId={payload.bookingID}
            status="Cancelled"
          />
        ),
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLaodingSubmit(false);
    }
  };

  const isLoading = loadingUpdateBooking || loading || loadingSubmit;
  const isPastDate = dateLib(selectedAvailability?.timeslot)
    .clone()
    .isBefore(dateLib().clone());

  const isBookingCancelled =
    booking?.status === BOOKING_STATUS.CANCELED ||
    booking?.status === BOOKING_STATUS.CANCELED_BY_PATIENT ||
    booking?.status === BOOKING_STATUS.CANCELED_BY_PHARMACY;

  const isCancelAvailable = initBooking
    ? isWithin24Hours(initBooking?.timeslot)
    : false;

  if (isBookingCancelled) {
    return <Navigate replace to={`/appointments/${booking.bookingID}`} />;
  }

  return (
    <PageWrapper title="Select Time">
      <Box>
        <Card
          sx={(theme) => ({
            p: "2rem",
            mb: selectedAvailability && "10rem",
            [theme.breakpoints.between("xs", "sm")]: {
              p: "1rem",
            },
          })}
        >
          <Header booking={booking} />
          <Divider
            sx={{
              mt: "1rem",
            }}
          />

          <Box
            sx={(theme) => ({
              my: "2rem",
              [theme.breakpoints.between("xs", "sm")]: {
                my: "1rem",
              },
            })}
          >
            <Typography
              variant={isMobile ? "body1" : "h6"}
              color="primary"
              gutterBottom
            >
              {" "}
              Select Date
            </Typography>

            <Calendar
              selectProps={{
                size: isMobile ? "small" : "medium",
              }}
              formControlProps={{
                sx: {
                  mx: 0,
                },
              }}
              renderViewDay={() => <Days />}
              defaultValue={dateLib(booking?.timeslot).clone()}
            />
          </Box>
          <Box>
            <Box
              sx={(theme) => ({
                my: "2rem",
                display: "flex",
                alignItems: "center",
                [theme.breakpoints.between("xs", "sm")]: {
                  my: "1rem",
                },
              })}
            >
              <Typography variant={isMobile ? "body1" : "h6"} color="primary">
                Select Time
              </Typography>
              <Tooltip title="The time showed is based on the clinics timezone">
                <InfoOutlined
                  color="primary"
                  sx={{
                    width: 14,
                    height: 14,
                    cursor: "pointer",
                  }}
                />
              </Tooltip>
            </Box>
            <div className="w-full min-h-52">
              <Availabilities booking={booking || undefined} />
            </div>
          </Box>
        </Card>

        <Fade
          in={selectedAvailability !== null}
          style={{
            background: theme.palette.common.white,
            width: "100%",
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              p: "1rem",
            }}
          >
            <Box
              width="100%"
              display="flex"
              justifyContent="center"
              alignItems="center"
              py=".5rem"
            >
              <Box
                sx={(theme) => ({
                  width: "30%",
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                  [theme.breakpoints.between("xs", "sm")]: {
                    gap: ".5rem",
                    width: "100%",
                  },
                  [theme.breakpoints.between("sm", "lg")]: {
                    width: "50%",
                    px: ".5rem",
                  },
                })}
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: ".5rem",
                  }}
                >
                  <AccessTimeOutlined color="primary" />
                  <Typography>
                    {dateLib(selectedAvailability?.timeslot)
                      .clone()
                      .format("dddd, MMMM DD YYYY - hh:mm A")}
                  </Typography>
                </Box>
                <Button
                  disabled={
                    JSON.stringify(initBooking) === JSON.stringify(booking) ||
                    isLoading ||
                    isBookingCancelled
                  }
                  variant="contained"
                  onClick={async () => {
                    if (!selectedAvailability) {
                      return;
                    }

                    if (!selectedAvailability.isAvailable) {
                      return;
                    }

                    if (
                      dateLib(selectedAvailability.timeslot)
                        .clone()
                        .isBefore(dateLib().clone())
                    ) {
                      openSnackbar({
                        message:
                          "You've missed this time. Please choose another time!",
                        severity: "warning",
                      });
                      return;
                    }

                    //  logic update appointmet
                    await handleUpdateBooking();
                  }}
                  fullWidth
                  size="medium"
                >
                  {isLoading ? <CircularProgress size={24} /> : "Update"}
                </Button>
                <Button
                  disabled={
                    isLoading ||
                    isPastDate ||
                    isBookingCancelled ||
                    isCancelAvailable
                  }
                  size="medium"
                  variant="outlined"
                  fullWidth
                  color="warning"
                  onClick={async () => {
                    if (!booking) {
                      return;
                    }

                    if (
                      dateLib(selectedAvailability?.timeslot)
                        .clone()
                        .isBefore(dateLib().clone())
                    ) {
                      openSnackbar({
                        message:
                          "You've missed this time. Please choose another time!",
                        severity: "warning",
                      });
                      return;
                    }

                    openModal({
                      title: "Are you sure?",
                      maxWidth: "sm",
                      fullWidth: true,
                      backdropClose: false,
                      content: (
                        <ModalConfirm
                          onOk={async () => {
                            await handleCancelBooking();
                          }}
                        />
                      ),
                    });
                  }}
                >
                  Cancel Appointment
                </Button>
                {isCancelAvailable && (
                  <Typography color="warning" variant="body2">
                    **For cancellations within 24 hours of your scheduled time,
                    contact the clinic directly at&nbsp;
                    <a href={`tel:${selectedPharmacy?.pharmacyPhoneNumber}`}>
                      {selectedPharmacy?.pharmacyPhoneNumber}
                    </a>
                    .
                  </Typography>
                )}
              </Box>
            </Box>
          </Box>
        </Fade>
      </Box>
    </PageWrapper>
  );
};

export default UpdateAppointment;
