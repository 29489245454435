import DatePickerMUI from "@/components/DatePickerMUI/DatePickerMUI";
import { CalendarIcon } from "@/components/Icons/Icons";
import { IBooking } from "@/interfaces/IBooking";
import { dateLib } from "@/lib/_calendar";
import { ShieldOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import moment, { Moment } from "moment";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { Link } from "react-router-dom";

export interface IErrorValidator {
  name: keyof IValidationForm;
  isError: boolean;
  message: string;
}

export interface IScreeningValidationFormProps {
  onValidate?: (isVerified: boolean) => void;
  booking?: IBooking;
}

export interface IValidationForm
  extends Pick<IBooking["patient"], "firstName" | "lastName" | "gender"> {
  dateOfBirth: string | Moment;
}

const ValidationForm = ({
  onValidate,
  booking,
}: IScreeningValidationFormProps) => {
  const methods = useForm<IValidationForm>({
    defaultValues: {
      lastName: "",
      dateOfBirth: "",
    },
  });

  // const [errors, setErrors] = useState<IErrorValidator[]>([]);

  const { handleSubmit, control, setError, clearErrors } = methods;
  const onSubmitValidate = (data: IValidationForm) => {
    if (!booking) {
      console.info("Booking data not initialized yet!");
      return;
    }

    // check lastName
    const isLastNameVerified =
      booking.patient.lastName.trim().toUpperCase() ===
      data.lastName.trim().toUpperCase();
    if (!isLastNameVerified) {
      setError("lastName", {
        message: "Last name does not match.",
      });
      console.info("Last name did not match");
    } else {
      clearErrors("lastName");
      console.info("Should clear last name errors");
    }

    // check dateOfBirth
    const isDobVerified = dateLib(data.dateOfBirth).isSame(
      dateLib(booking.patient.dateOfBirth),
      "date"
    );
    if (!isDobVerified) {
      setError("dateOfBirth", {
        message: "Date of birth does not match.",
      });
    } else {
      clearErrors("dateOfBirth");
    }

    const isVerified = isLastNameVerified && isDobVerified;

    if (typeof onValidate === "function") {
      onValidate(isVerified);
    }
  };

  return (
    <FormProvider {...methods}>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmitValidate)}
        sx={(theme) => ({
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "1.5rem",
          p: "0 1rem 1rem 1rem",
          [theme.breakpoints.between("xs", "sm")]: {
            p: "unset",
          },
        })}
      >
        <Controller
          control={control}
          rules={{
            required: {
              value: true,
              message: "This field is required",
            },
            validate: (value) =>
              value.trim().length !== 0 || "Empty value are not allowed",
          }}
          name="lastName"
          render={({ field, fieldState }) => {
            return (
              <TextField
                fullWidth
                placeholder="Your last name"
                {...field}
                helperText={fieldState.error?.message}
                error={!!fieldState.error}
              />
            );
          }}
        />
        <Controller
          name="dateOfBirth"
          rules={{
            required: {
              value: true,
              message: "This field is required",
            },
          }}
          control={control}
          render={({ field, fieldState }) => {
            const { value, onChange, ...rest } = field;
            return (
              <DatePickerMUI
                {...rest}
                label="Date Of Birth"
                value={moment(value)}
                disableFuture
                slots={{ openPickerIcon: CalendarIcon }}
                format="DD/MM/YYYY"
                onChange={onChange}
                slotProps={{
                  textField: {
                    required: true,
                    fullWidth: true,
                    error: !!fieldState.error,
                    helperText: fieldState.error?.message,
                    onChange,
                  },
                }}
              />
            );
          }}
        />

        <Typography textAlign="justify">
          By providing this information, you consent to Dragatron collecting and
          disclosing your personal and health information fo the purposes of
          managing your recent appointment. See our&nbsp;
          <a target="_blank" className="text-primary" href="/privacy">
            privacy collection
          </a>
          &nbsp; notice and&nbsp;
          <a target="_blank" className="text-primary" href="/privacy">
            privacy policy
          </a>
          . if you do not wish to give such consent, you must not proceed. For
          more information, please click the “Privacy at Dragatron" button.
        </Typography>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <Button fullWidth variant="contained" type="submit">
            Verify Identity
          </Button>
          <Link to={"/privacy"}>
            <Button fullWidth variant="outlined" startIcon={<ShieldOutlined />}>
              Privacy at DRAGATRON
            </Button>
          </Link>
        </Box>
      </Box>
    </FormProvider>
  );
};

export default ValidationForm;
