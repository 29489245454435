import { useApp } from "@/context/AppContext";
import { IService } from "@/interfaces/IService";
import { Box, CircularProgress, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import PharmacyService from "./PharmacyService";
import { useReservationEndpoint } from "@/hooks/useReservationEndpoint";

type PharmacyServicesProps = {
  searchBy?: string;
};

const PharmacyServices = ({ searchBy }: PharmacyServicesProps) => {
  const { selectedPharmacy } = useApp();
  const [services, setServices] = useState<IService[]>([]);
  const [initServices, setInitServices] = useState<IService[]>([]);

  const { getServiceByPharmacyID, loading, error } = useReservationEndpoint();

  useEffect(() => {
    if (selectedPharmacy) {
      getServiceByPharmacyID(selectedPharmacy.pharmacyID)
        .then((response) => {
          if (response?.data) {
            const result = response.data as IService[];
            const activeService = result.filter((service) => service.isActive);

            setServices(activeService);
            setInitServices(activeService);
          }
        })
        .catch((reason) =>
          console.error("Error getting service by pharmacy id ", reason)
        );
    }
  }, [selectedPharmacy]);

  // search logic
  useEffect(() => {
    if (searchBy !== undefined && searchBy.trim().length > 0) {
      const filteredService = services.filter((service) =>
        service.serviceName
          .toUpperCase()
          .includes(searchBy.toUpperCase().trim())
      );
      setServices(filteredService);
    } else {
      setServices(initServices);
    }
  }, [searchBy]);

  if (!selectedPharmacy) {
    return (
      <Box>
        <Typography>Please select a pharmacy first</Typography>
      </Box>
    );
  }

  if (loading) {
    return (
      <Box
        display="flex"
        width="100%"
        minHeight="30vh"
        justifyContent="center"
        alignItems="center"
      >
        <Box
          width="100%"
          height="100%"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          gap="1rem"
        >
          <CircularProgress />
          <Typography>Please wait...</Typography>
        </Box>
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        gap="1rem"
        justifyContent="center"
        alignItems="center"
        width="100%"
        minHeight="30vh"
      >
        <Box width="100%" height="100%">
          <Typography>
            Sorry, we are having a problem loading available service...
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Box
      component="ul"
      sx={{
        p: 0,
        li: {
          listStyle: "none",
          width: "100%",
        },
      }}
    >
      {services.map((service) => (
        <PharmacyService service={service} key={service.serviceId} />
      ))}
    </Box>
  );
};

export default PharmacyServices;
