import PageWrapper from "../PageWrapper";
import Box from "@mui/material/Box";
import {
  alpha,
  Card,
  CircularProgress,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Dragatron from "@/assets/img/dragatron.png";
import ScreeningHeader from "./ScreeningHeader";
import ScreeningForm from "./ScreeningForm";
import { Navigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { IBooking } from "@/interfaces/IBooking";
import { useReservationEndpoint } from "@/hooks/useReservationEndpoint";
import { IPatientAppointment } from "@/interfaces/IPatientAppointment";
import PatientValidator from "./PatientValidator";
import { IService } from "@/interfaces/IService";
import { App } from "@/lib/app";

type ScreeningsPageProps = {};

const Topbar = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  return (
    <Box
      component={"header"}
      sx={(theme) => ({
        bgcolor: theme.palette.common.white,
        width: "100%",
        minHeight: "7rem",
        display: "flex",
        height: "auto",
        alignItems: "center",
        justifyContent: "space-between",
        px: "2rem",
        [theme.breakpoints.between("xs", "sm")]: {
          width: "100%",
          justifyContent: "center",
        },
      })}
    >
      <Box
        sx={{
          height: "auto",
        }}
      >
        <a href="https://www.dragatron.com.au" target="_blank" rel="noreferrer">
          <img
            src={Dragatron}
            alt="Dragatron"
            className=" object-cover aspect-auto"
          />
        </a>
      </Box>
      {!isMobile && <Typography variant="h6">Screening</Typography>}
    </Box>
  );
};

const ScreeningsPage = (props: ScreeningsPageProps) => {
  const { bookingID } = useParams();

  const defaultVerified = App.getVeirifiedForScreening();

  const [booking, setBooking] = useState<IBooking | null>(null);
  const [patient, setPatient] = useState<IPatientAppointment | null>(null);
  const [service, setService] = useState<IService | null>(null);
  const [verified, setVerified] = useState<boolean>(defaultVerified.isVerified);
  const [loading, setLaoding] = useState(true);

  const { getBooking, getPatientByPatientID, error, getService } =
    useReservationEndpoint();

  useEffect(() => {
    if (bookingID) {
      getBooking(bookingID)
        .then((response) => {
          if (response?.data) {
            const bookingResult = response.data as IBooking;
            setBooking(response.data as IBooking);
            App.setVeirifiedForScreening({
              isVerified: bookingResult.IsAssessmentCompleted,
            });
          }
        })
        .catch((reason) =>
          console.error("Error getting booking detail ", reason)
        );
    }
  }, [bookingID]);

  useEffect(() => {
    if (booking) {
      getPatientByPatientID(booking.patient.patientID)
        .then((response) => {
          if (response?.data) {
            setPatient(response.data as IPatientAppointment);
          }
          setLaoding(false);
        })
        .catch((reason) =>
          console.error("Error getting patient detail ", reason)
        );

      getService(booking.serviceId)
        .then((response) => {
          if (response?.data) {
            setService(response.data as IService);
          }
          setLaoding(false);
        })
        .catch((reason) =>
          console.error("Error getting patient detail ", reason)
        );
    }
  }, [booking]);

  if (loading) {
    return (
      <PageWrapper title="Loading...">
        <Box
          sx={(theme) => ({
            background: alpha(theme.palette.primary.main, 0.2),
            width: "100%",
            minHeight: "100vh",
            display: "flex",
            flexDirection: "column",
            position: "relative",
          })}
        >
          <Box
            sx={(theme) => ({
              position: "sticky",
              top: 0,
              zIndex: theme.zIndex.appBar,
            })}
          >
            <Topbar />
          </Box>
          <Box
            position="relative"
            display="flex"
            justifyContent={"center"}
            alignItems={"start"}
          >
            <Box
              sx={(theme) => ({
                width: "50%",
                p: "1rem",
                display: "flex",
                flexDirection: "column",
                gap: "2rem",
                "& .MuiCard-root": {
                  p: "1rem",
                },
                [theme.breakpoints.between("xs", "sm")]: {
                  width: "100%",
                },
                [theme.breakpoints.between("sm", "md")]: {
                  width: "100%",
                },
                [theme.breakpoints.between("md", "lg")]: {
                  width: "70%",
                },
              })}
            >
              <ScreeningHeader />

              <Card
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                  justifyContent: "center",
                  alignItems: "center",
                  minHeight: "20rem",
                }}
              >
                <CircularProgress />
                <Typography>Please wait...</Typography>
              </Card>
            </Box>
          </Box>
        </Box>
      </PageWrapper>
    );
  }

  if (!loading && !booking && error) {
    return (
      <PageWrapper title="Loading...">
        <Box
          sx={(theme) => ({
            background: alpha(theme.palette.primary.main, 0.2),
            width: "100%",
            minHeight: "100vh",
            display: "flex",
            flexDirection: "column",
            position: "relative",
          })}
        >
          <Box
            sx={(theme) => ({
              position: "sticky",
              top: 0,
              zIndex: theme.zIndex.appBar,
            })}
          >
            <Topbar />
          </Box>
          <Box
            position="relative"
            display="flex"
            justifyContent={"center"}
            alignItems={"start"}
          >
            <Box
              sx={(theme) => ({
                width: "50%",
                p: "1rem",
                display: "flex",
                flexDirection: "column",
                gap: "2rem",
                "& .MuiCard-root": {
                  p: "1rem",
                },
                [theme.breakpoints.between("xs", "sm")]: {
                  width: "100%",
                },
                [theme.breakpoints.between("sm", "md")]: {
                  width: "100%",
                },
                [theme.breakpoints.between("md", "lg")]: {
                  width: "70%",
                },
              })}
            >
              <ScreeningHeader />

              <Card
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography color="error">
                  Sorry, we are having a problem processing your request.
                </Typography>
              </Card>
            </Box>
          </Box>
        </Box>
      </PageWrapper>
    );
  }

  const handleOnVerifing = (isVerified: boolean) => {
    setVerified(isVerified);
    App.setVeirifiedForScreening({ isVerified });
  };

  if (booking && booking.IsAssessmentCompleted) {
    return <Navigate to={"success"} />;
  }

  if (!verified && booking) {
    return <PatientValidator booking={booking} onValidate={handleOnVerifing} />;
  }

  return (
    <PageWrapper title="Appointment Detail">
      <Box
        sx={(theme) => ({
          background: alpha(theme.palette.primary.main, 0.2),
          width: "100%",
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          position: "relative",
        })}
      >
        <Box
          sx={(theme) => ({
            position: "sticky",
            top: 0,
            zIndex: theme.zIndex.appBar,
          })}
        >
          <Topbar />
        </Box>
        <Box
          position="relative"
          display="flex"
          justifyContent={"center"}
          alignItems={"start"}
        >
          <Box
            sx={(theme) => ({
              width: "50%",
              p: "1rem",
              display: "flex",
              flexDirection: "column",
              gap: "2rem",
              "& .MuiCard-root": {
                p: "1rem",
              },
              [theme.breakpoints.between("xs", "sm")]: {
                width: "100%",
              },
              [theme.breakpoints.between("sm", "md")]: {
                width: "100%",
              },
              [theme.breakpoints.between("md", "lg")]: {
                width: "70%",
              },
            })}
          >
            <ScreeningHeader />

            <Card
              sx={(theme) => ({
                display: "flex",
                flexDirection: "column",
              })}
            >
              <ScreeningForm
                booking={booking || undefined}
                patient={patient || undefined}
                service={service || undefined}
              />
            </Card>
          </Box>
        </Box>
      </Box>
    </PageWrapper>
  );
};

export default ScreeningsPage;
