import { Pharmacy } from "@/codegens/AIR-API/__generated__/graphql";
import {
  Bookings,
  PharmacyAvailability,
  Service,
  ServiceCategory,
  ServiceSchedule,
} from "@/codegens/HEALTCARE-RESERVATION/__generated__/graphql";
import { IAvailability, IAvailabilityRoom } from "@/interfaces/IAvailability";
import { IBooking } from "@/interfaces/IBooking";
import { IPharmacy } from "@/interfaces/IPharamacy";
import { IService, IServiceCategory } from "@/interfaces/IService";
import { IServiceSchedule } from "@/interfaces/IServiceSchedule";

export function formatToApharmacy(pharmacy: Pharmacy): IPharmacy {
  return {
    Suburb: pharmacy.Suburb!,
    pharmacyEmailAddress: pharmacy.pharmacyEmailAddress!,
    pharmacyPhoneNumber: pharmacy.pharmacyPhoneNumber!,
    id: pharmacy.id,
    pharmacyAddress: pharmacy.pharmacyAddress || "",
    pharmacyID: pharmacy.id,
    pharmacyName: pharmacy.pharmacyName || "",
    allowPayment:
      pharmacy.allowPayment !== null &&
      pharmacy.allowPayment !== undefined &&
      pharmacy.allowPayment !== false,
  };
}

export function formatToAService(service: Service | null): IService | null {
  if (!service) {
    return service;
  }

  return {
    durationInSeconds: service.durationInSeconds,
    isActive: service.isActive,
    pharmacyID: service.pharmacyID,
    serviceId: service.serviceId,
    serviceScheduleID: service.serviceScheduleID!,
    description: service!.description!,
    serviceName: service.serviceName!,
    price: {
      amount: service.price?.ammount || 0,
      currency: service.price?.currency || "aud",
      includeGst: service.price?.includeGst || false
    },
    allowPayment: service.allowPayment || false
  };
}

export function formatToAServiceCategory(
  serviceCategory: ServiceCategory | null
): IServiceCategory | null {
  if (!serviceCategory) {
    return serviceCategory;
  }

  return {
    categoryID: serviceCategory.categoryID,
    categoryDescription: serviceCategory.categoryDescription || "",
    categoryName: serviceCategory.categoryName || "",
  };
}

export function formatToAvailability(
  availability: PharmacyAvailability | null
): IAvailability | null {
  if (!availability) {
    return availability;
  }

  return {
    availabilityID: availability.availabilityID,
    durationInSeconds: availability.durationInSeconds,
    isAvailable: availability.isAvailable as boolean,
    maxSlotsAvailable: availability.maxSlotsAvailable,
    maxSlotsInStore: availability.maxSlotsInStore,
    rooms: availability.rooms as IAvailabilityRoom[],
    serviceId: availability.serviceId,
    timeslot: availability.timeslot,
  };
}

export function formatToAServiceSchedule(
  schedule: ServiceSchedule | null
): IServiceSchedule | null {
  if (!schedule) {
    return schedule;
  }

  return {
    isActive: schedule.isActive!,
    lastAvailableDate: schedule.lastAvailableDate!,
    serviceId: schedule.serviceId!,
    serviceScheduleID: schedule.serviceScheduleID,
    friday: {
      openingHour: schedule.friday!.openingHour!,
      closingHour: schedule.friday!.closingHour!,
      isClosed: schedule.friday?.isClosed!,
    },
    monday: {
      openingHour: schedule.monday?.openingHour!,
      closingHour: schedule.monday?.closingHour!,
      isClosed: schedule.monday?.isClosed!,
    },
    saturday: {
      openingHour: schedule.saturday?.openingHour!,
      closingHour: schedule.saturday?.closingHour!,
      isClosed: schedule.saturday?.isClosed!,
    },
    sunday: {
      openingHour: schedule.sunday?.openingHour!,
      closingHour: schedule.sunday?.closingHour!,
      isClosed: schedule.sunday?.isClosed!,
    },
    thursday: {
      openingHour: schedule.thursday?.openingHour!,
      closingHour: schedule.thursday?.closingHour!,
      isClosed: schedule.thursday?.isClosed!,
    },
    tuesday: {
      openingHour: schedule.tuesday?.openingHour!,
      closingHour: schedule.tuesday?.closingHour!,
      isClosed: schedule.tuesday?.isClosed!,
    },
    wednesday: {
      openingHour: schedule.wednesday?.openingHour!,
      closingHour: schedule.wednesday?.closingHour!,
      isClosed: schedule.wednesday?.isClosed!,
    },
  };
}

export const formatToABooking = (booking: Bookings | null): IBooking | null => {
  if (!booking) {
    return booking;
  }

  return {
    bookingID: booking.bookingID || "",
    availabilityID: booking.availabilityID || "",
    BookingMonth: booking.BookingMonth || "",
    createdDate: booking.createdDate || "",
    durationInSeconds: booking.durationInSeconds,
    patient: {
      addressLineOne: booking.patient?.addressLineOne || "",
      dateOfBirth: booking.patient?.dateOfBirth || "",
      emailAddress: booking.patient?.emailAddress || "",
      firstName: booking.patient?.firstName || "",
      gender: booking.patient?.gender || "",
      lastName: booking.patient?.lastName || "",
      patientID: booking.patient?.patientID || "",
      pharmacyID: booking.patient?.pharmacyID || "",
      phoneNumber: booking.patient?.phoneNumber || "",
      state: booking.patient?.state || "",
      locality: booking.patient?.locality || "",
      postCode: booking.patient?.postCode || ""
      
    },
    payment: {
      clientSecret: booking.payment?.clientSecret || "",
      status: booking.payment?.status || "CREATED",
      token: booking.payment?.token || booking.bookingID,
      channel: booking.payment?.channel || "STRIPE",
    },
    patientID: booking.patientID || "",
    pharmacyID: booking.pharmacyID || "",
    serviceId: booking.serviceId || "",
    status: booking.status || "",
    timeslot: booking.timeslot || "",
    notes: booking.notes || "",
    IsAssessmentCompleted: booking.IsAssessmentCompleted || false
  };
};
