import { CircularProgress, Tooltip, Paper, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useSnackbar } from "@/context/SnackbarContext";
import { IAvailability } from "@/interfaces/IAvailability";
import { useReservationEndpoint } from "@/hooks/useReservationEndpoint";
import { cn } from "@/lib/utils";
import { dateLib } from "@/lib/_calendar";
import { useApp } from "@/context/AppContext";
import { useCalendar } from "@/context/CalendarContext";
import { IBooking } from "@/interfaces/IBooking";
import { IWeekSchedule } from "@/interfaces/IPharmacySchedule";
import { IHour } from "@/interfaces/IHour";

interface AvailabilitiesProps {
  booking?: IBooking;
}
const Availabilities = ({ booking }: AvailabilitiesProps) => {
  const {
    selectedPharmacy,
    setSelectedAvailability,
    selectedAvailability,
    selectedService,
    schedule,
  } = useApp();
  const { selectedDate } = useCalendar();
  const [availabilities, setAvailabilities] = useState<IAvailability[]>([]);
  const { getAvailabilitiesByDay, loading, getRoomSchedule } =
    useReservationEndpoint();

  const { openSnackbar } = useSnackbar();

  useEffect(() => {
    if (selectedDate && selectedPharmacy && schedule) {
      getRoomSchedule(
        selectedPharmacy.pharmacyID,
        selectedDate.format("YYYY-MM-DD")
      ).then((response) => {
        if (response) {
          const schedule = response as any;
          console.log(schedule, "schedule");

          if (!schedule.isAvailable) {
            console.info("Unavailable rooms");
            return;
          }

          getAvailabilitiesByDay({
            pharmacyID: selectedPharmacy!.pharmacyID!,
            date: selectedDate,
            serviceId: selectedService?.serviceId,
          })
            .then((response) => {
              if (response?.data) {
                console.log(selectedAvailability, "selectedAvailability");

                const result = response.data as IAvailability[];
                // const filteredOnlyIsAavailableTime = result.filter(
                //   (available) => available.isAvailable
                // );
                setAvailabilities(result);
              }
            })
            .catch((reason) =>
              console.error("Error getting availabilities ", reason)
            );
        }
      });
    }
  }, [selectedDate, selectedPharmacy, schedule]);

  if (loading && availabilities.length === 0) {
    return (
      <div className="flex justify-center items-center w-full min-h-48">
        <CircularProgress />
      </div>
    );
  }

  if (!loading && availabilities.length === 0) {
    return (
      <div className="w-full flex justify-center text-md font-bold col-span-3 my-10">
        There is no appointment for this date
      </div>
    );
  }

  const getHoursInNumber = (hours: string | null | undefined) => {
    if (!hours) {
      return 0;
    }

    const hour = hours.split(":")[0];

    return Number(hour);
  };

  const getMinutesHoursInNumber = (hours: string | null | undefined) => {
    if (!hours) {
      return 0;
    }

    const minute = hours.split(":")[1];

    return Number(minute);
  };

  const today = dateLib(selectedDate).clone().tz("Australia/Sydney");
  const todaySchedule = schedule?.[
    today.format("dddd").toLowerCase() as keyof IWeekSchedule
  ] as IHour;

  const openTime = today
    .clone()
    .set("hour", getHoursInNumber(todaySchedule?.openingHour))
    .set("minute", getMinutesHoursInNumber(todaySchedule?.openingHour))
    .set("millisecond", 0);

  const closeTime = today
    .clone()
    .set("hour", getHoursInNumber(todaySchedule?.closingHour))
    .set("minute", getMinutesHoursInNumber(todaySchedule?.closingHour))
    .set("millisecond", 0);

  const filteredByOpenAndCloceSchedule = availabilities.filter((availability) =>
    dateLib(availability.timeslot)
      .clone()
      .isBetween(
        dateLib(openTime).clone(),
        dateLib(closeTime).clone(),
        "hour",
        "[)"
      )
  );

  return (
    <div className="grid grid-cols-3 gap-4 relative">
      {Array.isArray(filteredByOpenAndCloceSchedule) &&
        filteredByOpenAndCloceSchedule.map((availability) => {
          const isSelected =
            selectedAvailability?.availabilityID ===
            availability.availabilityID;

          const isDefaultSelectedForEdit =
            availability.availabilityID === booking?.availabilityID;

          if (!availability.isAvailable && isDefaultSelectedForEdit) {
            return (
              <Paper
                key={availability.availabilityID}
                onClick={() => {
                  setSelectedAvailability(availability);
                }}
                className={cn(
                  "flex bg-white  border-white border-2 border-solid items-center justify-center shadow-md py-3 rounded-lg  font-medium hover:border-primary hover:border-solid hover:border-2 transition hover:shadow-primary/10 cursor-pointer hover:text-primary hover:font-semibold",
                  isDefaultSelectedForEdit &&
                    "border-primary border-solid border-2 shadow-primary/10 text-primary font-semibold"
                )}
              >
                <Typography>
                  {dateLib(availability.timeslot).clone().format("hh:mm A")}
                </Typography>
              </Paper>
            );
          }

          if (!availability.isAvailable && isSelected) {
            return (
              <Tooltip
                title="This time is not available"
                key={availability.availabilityID}
              >
                <Paper
                  className={cn(
                    "flex bg-white  border-white border-2 border-solid items-center justify-center shadow-md py-3 rounded-lg  font-medium   cursor-not-allowed"
                  )}
                  onClick={() => {
                    openSnackbar({
                      message: "Unavailable time",
                    });
                  }}
                >
                  <Typography>
                    {dateLib(availability.timeslot).clone().format("hh:mm A")}
                  </Typography>
                </Paper>
              </Tooltip>
            );
          }

          // disable past time
          if (dateLib(availability.timeslot).clone().isBefore(dateLib())) {
            return null;
          }

          return (
            <Paper
              key={availability.availabilityID}
              onClick={() => {
                setSelectedAvailability(availability);
              }}
              className={cn(
                "flex bg-white  border-white border-2 border-solid items-center justify-center shadow-md py-3 rounded-lg  font-medium hover:border-primary hover:border-solid hover:border-2 transition hover:shadow-primary/10 cursor-pointer hover:text-primary hover:font-semibold",
                isSelected &&
                  "border-primary border-solid border-2 shadow-primary/10 text-primary font-semibold"
              )}
            >
              <Typography>
                {dateLib(availability.timeslot).clone().format("hh:mm A")}
              </Typography>
            </Paper>
          );
        })}
    </div>
  );
};

export default Availabilities;
