import {
  Box,
  Container,
  Paper,
  Skeleton,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import { IDay, useCalendar } from "../../../context/CalendarContext";
import { nanoid } from "nanoid";
import { cn } from "../../../lib/utils";
import { useApp } from "../../../context/AppContext";
import { useSnackbar } from "@/context/SnackbarContext";

type Props = {};

const Days = () => {
  const { selectedDate, days, setSelectedDate, loading } = useCalendar();
  const { openSnackbar } = useSnackbar();

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          width: "100%",
          flexDirection: "column",
          mx: 0,
        }}
      >
        <Box
          sx={{
            py: ".5rem",
            display: "flex",
            flex: 7,
            gap: ".5rem",
            overflowX: "scroll",
          }}
        >
          {/* Create an array of 7 skeleton placeholders */}
          {Array.from(new Array(7)).map((_, index) => {
            return (
              <Box
                key={index}
                sx={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "12rem",
                  py: 2,
                  px: 1,
                  borderRadius: "8px",
                  boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
                  backgroundColor: "white",
                  border: "2px solid #f0f0f0",
                }}
              >
                <Typography variant="body2">
                  <Skeleton
                    sx={{
                      width: "1rem",
                    }}
                  />
                </Typography>
                <Typography variant="h6">
                  <Skeleton
                    sx={{
                      width: "2rem",
                    }}
                  />
                </Typography>
              </Box>
            );
          })}
        </Box>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        flexDirection: "column",
        mx: 0,
      }}
    >
      <Box
        sx={{
          py: ".5rem",
          display: "flex",
          flex: 7,
          gap: ".5rem",
          overflowX: "scroll",
        }}
      >
        {days.map((data) => {
          const isSelected = selectedDate.isSame(data.date, "date");
          const isDisable = data.disable || data.schedule.isClosed === true;

          return (
            <Tooltip title={""} key={nanoid()}>
              <Box
                component="button"
                key={data.date.unix()}
                // disabled={isDisable}
                onClick={() => {
                  if (isDisable) {
                    openSnackbar({
                      message: "Unavailable Time",
                      severity: "info",
                    });
                    return;
                  }

                  setSelectedDate(data.date);
                }}
                className={cn(
                  "xl:rounded-md lg:rounded-md md:rounded-md rounded-2xl xl:shadow-md lg:shadow-md md:shadow-md shadow-sm xl:shadow-grey-200/30 lg:shadow-grey-200/30 md:shadow-grey-200/30 shadow-grey-200/30 xl:mr-1 lg:mr-1 md:mr-1 xl:text-grey lg:text-grey md:text-grey text-grey xl:bg-white lg:bg-white md:bg-white bg-white xl:py-2 md:py-2 lg:py-2 xl:border-white lg:border-white md:border-white border-white lg:border-2 xl:border-2 md:border-2 lg:border-solid xl:border-solid md:border-solid",
                  isSelected &&
                    "xl:border-primary md:border-primary lg:border-primary border-primary xl:border-solid lg:border-solid md:border-solid border-solid xl:border-[2px] md:border-[2px] lg:border-[2px] border-[2px] xl:text-primary lg:text-primary md:text-primary text-primary ",
                  // data.isToday &&
                  //   "xl:border-secondary md:border-secondary lg:border-secondary border-secondary xl:border-solid lg:border-solid md:border-solid border-solid xl:border-[2px] md:border-[2px] lg:border-[2px] border-[2px] xl:text-secondary lg:text-secondary md:text-secondary text-secondary ",
                  isDisable
                    ? "text-grey/40 cursor-not-allowed"
                    : "cursor-pointer"
                )}
                sx={(theme) => ({
                  flex: 1,
                })}
              >
                <Container className="xl:flex lg:flex md:flex xl:flex-col lg:flex-col md:flex-col xl:items-center  lg:items-center  md:items-center xl:justify-center lg:justify-center md:justify-center xl:w-auto lg:w-auto md:w-auto  flex flex-col items-center justify-center w-12 p-3">
                  <Typography variant="body2">
                    {data.date.format("dddd").substring(0, 3)}
                  </Typography>
                  <Typography variant="h6" className="font-semibold">
                    {data.date.format("DD")}
                  </Typography>
                </Container>
              </Box>
            </Tooltip>
          );
        })}
      </Box>
    </Box>
  );
};

export default Days;
