import { ArrowIcon } from "@/components/Icons/Icons";
import { alpha, Box, Card, Typography } from "@mui/material";
import PageWrapper from "../PageWrapper";
import { useNavigate } from "react-router-dom";

type Props = {};

const BackButton = () => {
  const navigate = useNavigate()
  return (
    <div className="lg:flex xl:flex  xl:gap-4 lg:gap-4 flex gap-4">
      <span role="button" tabIndex={-1} onClick={() => navigate(-1)}>
        <div className="xl:w-7 lg:w-7 md:w-7 w-8 xl:h-7 lg:h-7 md:h-7 h-8 border-primary border-solid border-[.07rem] rounded-md flex items-center justify-center shadow-sm shadow-grey-400/40 hover:shadow-md hover:shadow-primary/20 cursor-pointer">
          <ArrowIcon className="fill-primary rotate-90" />
        </div>
      </span>
      <Typography variant="h6">Privacy at Dragatron</Typography>
    </div>
  );
};

const PrivacyHeader = () => {
  return (
    <Card
      sx={(theme) => ({
        display: "flex",
        flexDirection: "row",
        [theme.breakpoints.between("xs", "sm")]: {
          flexDirection: "column",
        },
      })}
    >
      <BackButton />
    </Card>
  );
};

const Topbar = () => {
  // const theme = useTheme();
  // const isMobile = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  return (
    <Box
      component={"header"}
      sx={(theme) => ({
        bgcolor: theme.palette.common.white,
        width: "100%",
        minHeight: "7rem",
        display: "flex",
        height: "auto",
        alignItems: "center",
        justifyContent: "space-between",
        px: "2rem",
        [theme.breakpoints.between("xs", "sm")]: {
          width: "100%",
          justifyContent: "center",
        },
      })}
    >
      {/* <Box
        sx={{
          height: "auto",
        }}
      >
        <a href="https://www.dragatron.com.au" target="_blank" rel="noreferrer">
          <img
            src={Dragatron}
            alt="Dragatron"
            className=" object-cover aspect-auto"
          />
        </a>
      </Box>
      {!isMobile && <Typography variant="h6">Appointment Detail</Typography>} */}
    </Box>
  );
};

const PrivacyPage = (props: Props) => {
  return (
    <PageWrapper title="Privacy">
      <Box
        sx={(theme) => ({
          background: alpha(theme.palette.primary.main, 0.2),
          width: "100%",
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          position: "relative",
        })}
      >
        <Box
          sx={(theme) => ({
            position: "sticky",
            top: 0,
            zIndex: theme.zIndex.appBar,
          })}
        >
          <Topbar />
        </Box>
        <Box
          position="relative"
          display="flex"
          justifyContent={"center"}
          alignItems={"start"}
        >
          <Box
            sx={(theme) => ({
              width: "50%",
              p: "1rem",
              display: "flex",
              flexDirection: "column",
              gap: "2rem",
              "& .MuiCard-root": {
                p: "1rem",
              },
              [theme.breakpoints.between("xs", "sm")]: {
                width: "100%",
              },
              [theme.breakpoints.between("sm", "md")]: {
                width: "100%",
              },
              [theme.breakpoints.between("md", "lg")]: {
                width: "70%",
              },
            })}
          >
            <PrivacyHeader />

            <Card
              sx={(theme) => ({
                display: "flex",
                flexDirection: "column",
              })}
            >
              <Typography paragraph textAlign="justify">
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Qui
                cupiditate voluptates, error nemo, molestiae enim iure
                perspiciatis velit natus ipsum suscipit quas laudantium aliquam
                illum numquam eveniet fugit. Odio, rerum.
              </Typography>
              <Typography paragraph textAlign="justify">
                Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                Exercitationem quidem doloremque, nihil consequatur asperiores
                eligendi harum illo, quis voluptatum obcaecati aliquam optio
                dolorum possimus rerum. Esse incidunt quibusdam possimus error.
              </Typography>
              <Typography paragraph textAlign="justify">
                Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                Recusandae enim quas architecto deserunt ipsum doloribus,
                debitis eum quo, repudiandae rerum consectetur sapiente, qui
                quisquam exercitationem distinctio. Id repellat adipisci
                quisquam.
              </Typography>
            </Card>
          </Box>
        </Box>
      </Box>
    </PageWrapper>
  );
};

export default PrivacyPage;
