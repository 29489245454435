import { Box, Button, Card, CircularProgress, Typography } from "@mui/material";

import {
  extractFullname,
  getSuburbsFromPlacesResult,
  uuid,
} from "../../../lib/utils";
import { Link, useNavigate } from "react-router-dom";

import AppoinmentDetail from "./AppointmentDetail";
import PatientDetailForm, { IAppointmentForm } from "./PatientDetailForm";
import { ICreatePatientPayload } from "../../../interfaces/IPatient";
import { FormProvider, useForm } from "react-hook-form";
import moment from "moment";
import { CreateBookingMutationVariables } from "../../../codegens/HEALTCARE-RESERVATION/__generated__/graphql";
import { useApp } from "../../../context/AppContext";
import { useModal } from "../../../context/DialogContext";
import ModalReservationCreated from "./ModalReservationCreated";
import ModalReservationFailed from "./ModalReservationFailed";
import { BOOKING_STATUS } from "../../../interfaces/IBooking";
import { BackNavigation } from "../../../components/new/BackNavigation";
import useApi from "../../../hooks/useApi";
import { useEffect, useState } from "react";
import ModalLoadingContent from "@/components/ModalLoadingContent/ModalLoadingContent";
import { steeperPath } from "@/constant/stepper";

const ReviewAppoinment = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const api = useApi();
  const {
    selectedAvailability,
    selectedPharmacy,
    selectedService,
    selectedCategory,
    selectedLocation,
  } = useApp();
  const { openModal, closeModal } = useModal();

  const methods = useForm<IAppointmentForm>({
    defaultValues: {
      fullName: "",
      dateOfBirth: null,
      gender: "",
      addressLineOne: "",
      emailAddress: "",
      phoneNumber: "",
      suburb: "",
      postCode: "",
      state: "",
      notes: "",
    },
  });

  const { errors } = methods.formState;

  useEffect(() => {
    if (loading) {
      openModal({
        title: null,
        backdropClose: false,
        content: <ModalLoadingContent />,
      });
    }
  }, [loading]);

  const handleSubmit = async (data: IAppointmentForm) => {
    // console.log(data, "data");
    try {
      setLoading(true);

      // patient payload
      const { fullName, suburb, dateOfBirth, notes, ...restPatientData } = data;
      const fullNames = extractFullname(fullName);
      const id = uuid();
      const patientPayload: ICreatePatientPayload = {
        lastName: fullNames?.lastName || "",
        middleName: fullNames?.middleName || "",
        firstName: fullNames?.firstName,
        patientID: id,
        pharmacyID: selectedPharmacy?.pharmacyID,
        createdDate: moment().format("YYYY-MM-DD"),
        dateOfBirth: moment(dateOfBirth).format("YYYY-MM-DD"),
        // dateOfBirth: moment(dateOfBirth).toISOString(),
        ...restPatientData,
      };

      // console.log(patientPayload, "patientPayload");

      const bookingID = uuid();
      const bookingPayload: CreateBookingMutationVariables["input"] = {
        availabilityID: selectedAvailability!.availabilityID,
        bookingID,
        durationInSeconds: selectedAvailability!.durationInSeconds,
        patientID: patientPayload.patientID,
        pharmacyID: selectedPharmacy!.pharmacyID!,
        serviceId: selectedService!.serviceId!,
        timeslot: selectedAvailability!.timeslot!,
        status: BOOKING_STATUS.CONFIRMED,
        BookingMonth:
          moment.months()[moment(selectedAvailability!.timeslot!).month()],
        createdDate: moment().toISOString(),
        notes,
        patient: {
          patientID: patientPayload.patientID,
          pharmacyID: selectedPharmacy!.pharmacyID!,
          phoneNumber: patientPayload.phoneNumber,
          firstName: patientPayload.firstName,
          lastName: patientPayload.lastName,
          dateOfBirth: patientPayload.dateOfBirth,
          addressLineOne: patientPayload.addressLineOne,
          emailAddress: patientPayload.emailAddress,
          gender: patientPayload.gender,
          state: patientPayload.state,
          locality: suburb,
          postCode: patientPayload.postCode,
        },
        IsAssessmentCompleted: false,
      };

      if (selectedPharmacy?.allowPayment || selectedService?.allowPayment) {
        bookingPayload.payment = {
          channel: "STRIPE",
          clientSecret: null,
          status: BOOKING_STATUS.CREATED,
          token: bookingID,
        };
      }

      // console.info("bookingPayload ", bookingPayload);

      // Create the booking
      const response = await api.post("/createAppointment", {
        ...bookingPayload,
        source: "WEB",
      });
      if (response.status !== 200) {
        throw new Error(response.data);
      }

      // setPrevReservation(bookingPayload);
      // close modal loading first
      closeModal();

      if (selectedPharmacy?.allowPayment || selectedService?.allowPayment) {
        // console.log("Should redirect");
        navigate(
          `/appointments/${
            selectedCategory?.categoryID
          }/${getSuburbsFromPlacesResult(selectedLocation)}/${
            selectedService?.serviceId
          }/${bookingPayload.availabilityID}/${bookingPayload.bookingID}/${
            steeperPath.PAYMENT_DETAIL
          }`
        );
      } else {
        // open modal succeess
        openModal({
          title: null,
          fullWidth: true,
          backdropClose: false,
          content: (
            <ModalReservationCreated bookingId={bookingPayload.bookingID} />
          ),
        });
      }
    } catch (error) {
      console.error("error creating reservation ", error);
      // close modal loading first
      closeModal();

      // open modal error
      openModal({
        title: null,
        fullWidth: true,
        backdropClose: false,
        content: <ModalReservationFailed error={error as Error} />,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <FormProvider {...methods}>
      <Box
        component="form"
        autoComplete="off"
        onSubmit={methods.handleSubmit(handleSubmit)}
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <Card
          sx={(theme) => ({
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            p: "2rem",
            [theme.breakpoints.between("xs", "sm")]: {
              p: "1rem",
            },
          })}
        >
          <BackNavigation
            title="Review Your Details"
            description="Please make sure your details belong to the individual visiting clinic"
            onClick={() => navigate(-1)}
          />

          <PatientDetailForm
            errors={errors}
            setValue={methods.setValue}
            control={methods.control}
          />
        </Card>
        <Card
          sx={(theme) => ({
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            p: "2rem",
            [theme.breakpoints.between("xs", "sm")]: {
              p: "1rem",
            },
          })}
        >
          <AppoinmentDetail />
        </Card>

        {/* <Card
        className={cn(
          "xl:px-8 lg:px-8 md:px-8 xl:py-4 lg:py-4 md:py-4 xl:mb-2 lg:mb-2 md:mb-2 xl:rounded-2xl lg:rounded-2xl md:rounded-2xl xl:shadow-xl lg:shadow-lg md:shadow-md shadow-xl rounded-2xl px-4 py-4 mb-2 rounded-tl-2xl rounded-bl-none rounded-tr-2xl rounded-br-none"
        )}
      >
        <div className="xl:w-full lg:w-full md:w-full xl:flex lg:flex md:flex xl:flex-col lg:flex-col md:flex-col xl:gap-6 lg:gap-6 md:gap-6 xl:my-6 md:my-6 lg:my-6 w-full flex flex-col gap-6 my-2">
          <FormLabel htmlFor="voucherCode" className="flex items-center gap-4">
            <VoucherIcon className="w-5 h-5" />
            <Typography className=" text-grey-200 font-medium">
              Voucher Code
            </Typography>
          </FormLabel>
          <TextField
            label="Code"
            id="voucherCode"
            fullWidth
            placeholder="eg: 5434"
            onChange={(e: any) => {
              setVoucherCode(e.target.value);
            }}
          />
        </div>
      </Card> */}

        <Card
          sx={(theme) => ({
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            p: "2rem",
            [theme.breakpoints.between("xs", "sm")]: {
              p: "1rem",
            },
          })}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
            }}
          >
            <Typography className="text-grey-200">Declaration</Typography>
            <div className="flex flex-col gap-4 text-justify">
              <Typography>
                By Clicking Continue, you agree to the&nbsp;
                <Link to="/privacy-statement" className="text-primary">
                  Privacy Statement
                </Link>
                &nbsp; and&nbsp;
                <Link to="/terms-conditions" className="text-primary">
                  Terms and Use
                </Link>
                &nbsp; of the Health Appointment System.
              </Typography>
              <Button
                fullWidth
                type="submit"
                size="large"
                variant="contained"
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress size={24} />
                ) : (
                  <Typography>Continue</Typography>
                )}
              </Button>
            </div>
          </Box>
        </Card>
      </Box>
    </FormProvider>
  );
};

export default ReviewAppoinment;
